import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../assets/logo.png";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import Flag from "../assets/flaga.webp";
import FlagaUE from "../assets/flaga_ue.jpg";
import { Link } from "gatsby";
const Navbar = () => {
  const [isOpen, toggleMenu] = useState(false);

  const menuFunc = () => {
    console.log("it works", isOpen);
    toggleMenu(!isOpen);
  };

  return (
    <>
      <MobileNavigation isOpen={isOpen}>
        <div className="close-menu">
          <MdClose onClick={menuFunc} style={{ fontSize: "24px" }} />
        </div>
        <ul>
          <li onClick={menuFunc}>
            <Link to="/">Home</Link>
          </li>
          <li onClick={menuFunc}>
            <Link to="/restauracja">Restauracja</Link>
          </li>
          <li onClick={menuFunc}>
            <Link to="/noclegi">Noclegi</Link>
          </li>
          <li onClick={menuFunc}>
            <Link to="/przyjecia">Przyjecia</Link>
          </li>
          <li onClick={menuFunc}>
            <Link to="/galeria">Galeria</Link>
          </li>
          <li onClick={menuFunc}>
            <img src={Flag} />
            <Link to="/fundusze-europejskie">Fundusze Europejskie</Link>
          </li>
          <li onClick={menuFunc}>
            <Link to="/kontakt">Kontakt</Link>
          </li>
        </ul>
      </MobileNavigation>
      <WrapperNav>
        <UpperNav>
          <div className="left-social">
            <a href="https://pl-pl.facebook.com/Restauracja-Stragona-983577401652356/?ref=page_internal">
              <BsFacebook />
            </a>

            <div className="mobile-menu">
              <FaBars onClick={menuFunc} />
            </div>
          </div>
          <div className="logo">
            <Link to="/">
              <img src={Logo} />
            </Link>
          </div>
          <a
            href="https://www.instagram.com/restauracja_stragona/"
            className="right-menu"
          >
            <p>
              <BsInstagram />
            </p>
          </a>
        </UpperNav>
        <BottomNav>
          <nav>
            <img src={FlagaUE} className="flag" />
            <ul>
              <li>
                <Link to="/restauracja">Restauracja</Link>
              </li>
              <li>
                <Link to="/noclegi">Noclegi</Link>
              </li>
              <li>
                <Link to="/przyjecia">Przyjęcia</Link>
              </li>
              <li>
                <Link to="/galeria">Galeria</Link>
              </li>
              <li>
                <img src={Flag} />
                <Link to="/fundusze-europejskie">Fundusze Europejskie</Link>
              </li>
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
            </ul>
          </nav>
        </BottomNav>
      </WrapperNav>
    </>
  );
};

const WrapperNav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  overflow: hidden;
`;

const MobileNavigation = styled.div`
  .close-menu {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 40px;
    left: 35px;

    svg {
      font-size: 20px;
      color: #fefefe;
      display: block;
    }
  }
  width: 100vw;
  height: 100vh;
  z-index: 13;
  background-color: #222c2b;
  transform: ${(props) =>
    props.isOpen ? "translateY(0)" : "translateY(-100vh)"};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    li {
      a {
        text-decoration: none;
        color: #fefefe;
      }
      text-transform: uppercase;
      text-decoration: none;
      list-style-type: none;
      color: white;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin: 10px;
      opacity: ${(props) => (props.isOpen ? "1" : "0")};
      transition-delay: 2s;
      transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);

      img {
        width: 25px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }
`;

const UpperNav = styled.div`
  @media (max-width: 1000px) {
    border: none;
  }
  height: 100px;

  width: 100vw;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    color: #fefefe;
    font-weight: 300;
    font-size: 20px;
    padding: 15px;
    img {
      height: 62px;
      @media (max-width: 1000px) {
        height: 40px;
      }
    }
  }
  .left-social {
    z-index: 14;
    .mobile-menu {
      width: 80px;
      height: 80px;
      display: none;

      svg {
        font-size: 20px;
        color: #fefefe;
        display: block;
      }
      @media (max-width: 1000px) {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 14;
      }
    }
    width: 140px;
    height: 100%;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 14;
    @media (max-width: 1000px) {
      border: none;
      z-index: 14;
    }
    svg {
      color: #fefefe;
      font-size: 26px;
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
  .right-menu {
    @media (max-width: 1000px) {
      border: none;
    }
    width: 140px;
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      font-size: 24px;
    }
    p {
      color: #fefefe;
      text-transform: uppercase;
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
`;

const BottomNav = styled.div`
  position: relative;
  width: 100vw;
  height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  @media (max-width: 1000px) {
    display: none;
  }
  nav {
    .flag {
      height: 40px;
      position: absolute;
      left: 40px;
      top: 10px;
    }
    width: 50%;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        a {
          text-decoration: none;
          color: #fefefe;
        }
        text-transform: uppercase;
        margin-left: 10px;
        margin-right: 10px;
        text-decoration: none;
        list-style-type: none;
        color: white;
        font-size: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 25px;
          height: 15px;
          margin-right: 5px;
        }
      }
    }
  }
`;

export default Navbar;
