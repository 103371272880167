import React from "react";
import styled from "styled-components";
import EuroIMG from "../assets/logotypy.jpg";
import Logo from "../assets/logo.png";
import {
  AiOutlinePhone,
  AiOutlineMail,
  AiOutlineFacebook,
  AiOutlineInstagram,
} from "react-icons/ai";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <div className="info">
          <ul className="navigation">
            <li>
              <Link to="/restauracja">Restauracja</Link>
            </li>
            <li>
              <Link to="/noclegi">Noclegi</Link>
            </li>
            <li>
              <Link to="/przyjecia">Przyjecia</Link>
            </li>
            <li>Galeria</li>
            <li>
              <Link to="/fundusze-europejskie">Fundusze Europejskie</Link>
            </li>
            <li>
              <Link to="/kontakt">Kontakt</Link>
            </li>
          </ul>
          <ul className="address">
            <li>STRAGONA – Restauracja i Noclegi</li>
            <li>Al. Wojska Polskiego 47</li>
            <li>58-150 Strzegom</li>
            <li>
              <AiOutlinePhone /> <a href="tel:663776493">663-776-493</a>
            </li>
            <li>
              <AiOutlineMail /> biuro@stragona.com
            </li>
          </ul>
          <ul className="social">
            <li>
              <a href="https://www.facebook.com/Restauracja-Stragona-983577401652356/?ref=page_internal">
                <AiOutlineFacebook style={{ fontSize: "40px" }} />
              </a>
            </li>
            <li>
              <AiOutlineInstagram style={{ fontSize: "40px" }} href="" />
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  width: 100vw;
  min-height: 180px;
  background-color: #222c2b;
  z-index: 3;
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    max-height: 140px;
    max-width: 80%;
    padding: 50px;
    @media (max-width: 768px) {
      padding: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .container {
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 572px) {
      flex-direction: column;
    }
    .logo {
      display: flex;
      justify-content: center;

      @media (max-width: 572px) {
        height: 88px;
      }
      img {
        width: 200px;
      }
    }
    .info {
      width: 100%;
      ul {
        padding: 40px;
        @media (max-width: 578px) {
          padding: 0;
        }
        &.address {
          li {
            a {
              text-decoration: none;
              color: #f8f4ef;
            }
          }

          @media (max-width: 572px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
        }
        &.social {
          @media (max-width: 572px) {
            display: flex;
            flex-direction: row;
            flex: 1;
            justify-content: center;
          }
        }
        &.navigation {
          li {
            a {
              text-decoration: none;
              color: #f8f4ef;
            }
          }
          @media (max-width: 980px) {
            display: none;
          }
        }
        list-style-type: none;

        li {
          color: #f8f4ef;
          margin: 8px;
          display: flex;
          align-items: center;
          a {
            color: #f8f4ef;
          }
          svg {
            margin-right: 8px;
          }
        }
      }
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
`;

export default Footer;
