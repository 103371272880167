import React from "react";
import "./src/styles/global.css"
import Layout from "./src/components/Layout";
import SimpleReactLightbox from 'simple-react-lightbox'


export function wrapPageElement({ element, props }) {

    return (

        <SimpleReactLightbox><Layout {...props}>{element}</Layout></SimpleReactLightbox>

    )
}