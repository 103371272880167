exports.components = {
  "component---src-pages-fundusze-europejskie-js": () => import("./../../../src/pages/fundusze-europejskie.js" /* webpackChunkName: "component---src-pages-fundusze-europejskie-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-noclegi-js": () => import("./../../../src/pages/noclegi.js" /* webpackChunkName: "component---src-pages-noclegi-js" */),
  "component---src-pages-przyjecia-js": () => import("./../../../src/pages/przyjecia.js" /* webpackChunkName: "component---src-pages-przyjecia-js" */),
  "component---src-pages-restauracja-js": () => import("./../../../src/pages/restauracja.js" /* webpackChunkName: "component---src-pages-restauracja-js" */)
}

