import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import MobileNavScroll from "./MobileNavScroll";
const isBrowser = () => typeof window !== "undefined";

const Layout = ({ children }) => {
  useEffect(() => {
    if (isBrowser()) {
      let burgerButton = document.querySelector(".burger");
      let showedMenu = document.querySelector(".scroll_nav-open");
      let body = document.querySelector("body");
      let scrollNavUl = document.querySelector(".scroll_nav-open ul");
      scrollNavUl.addEventListener("click", () => {
        body.classList.remove("prevent-scroll");
        burgerButton.classList.remove("rotate");
        showedMenu.classList.remove("show");
      });
      window.addEventListener(
        "scroll",
        () => {
          if (!showedMenu.classList.contains("show")) {
            let nav = document.querySelector(".scroll_nav");
            nav.classList.toggle("hide", window.scrollY < 180);
            let logo = document.querySelector(".scroll_nav .logo");
            logo.classList.toggle("hide", window.scrollY < 180);
            burgerButton.classList.toggle("hide", window.scrollY < 180);
          }

          // DAC TUTAJ IFA PRZY MOBILCE
          // let nav = document.querySelector("header");
          // nav.classList.toggle("section_size", window.scrollY > 0);
          // let logo = document.querySelector("small");
          // logo.classList.toggle("section_size", window.scrollY > 0);
        },
        { passive: true }
      );
      burgerButton.addEventListener("click", () => {
        burgerButton.classList.toggle("rotate");
        body.classList.toggle("prevent-scroll");
        let nav2 = document.querySelector(".scroll_nav");
        showedMenu.classList.toggle("show");
        if (!nav2.classList.contains("hide")) {
          console.log("contains show");
        }
      });
    }
  }, []);
  return (
    <>
      <Navbar />
      <MobileNavScroll />

      {children}
      <Footer />
    </>
  );
};

export default Layout;
