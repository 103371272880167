import React from "react";
import Flag from "../assets/flaga.webp";
import { Link } from "gatsby";
import Logo from "../assets/logo_letter.png";
import styled from "styled-components";

const MobileNavScroll = () => {
  return (
    <>
      <div className="scroll_nav hide">
        <div className="burger hide">
          <span className="burger_button"></span>
          <span className="menu_text">MENU</span>
        </div>
        <div className="logo hide">
          <img src={Logo} alt="" />
        </div>
        {/* <ul>
                <li>Wynajmij</li>
                <li>Cennik</li>
                <li>Pokoje</li>
                <li>Galeria</li>
                <li><img src={Flag} /><Link to="/fundusze-europejskie">Fundusze Europejskie</Link></li>
                <li>Kontakt</li>
            </ul> */}
      </div>
      <div className="scroll_nav-open">
        <StyledMenuInside>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/restauracja">Restauracja</Link>
          </li>
          <li>
            <Link to="/noclegi">Noclegi</Link>
          </li>
          <li>
            <Link to="/przyjecia">Przyjecia</Link>
          </li>
          <li>
            <Link to="/galeria">Galeria</Link>
          </li>
          <li>
            <img src={Flag} alt="" />
            <Link to="/fundusze-europejskie">Fundusze Europejskie</Link>
          </li>
          <li>
            <Link to="/kontakt">Kontakt</Link>
          </li>
        </StyledMenuInside>
      </div>
    </>
  );
};

const MenuShowed = () => {};

const StyledMenuInside = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  list-style-type: none;
  height: 90%;
  padding-left: 0;
  li {
    color: #919695;
    font-size: 52px;
    font-family: "Josefin Slab", serif;
    margin: 5px;
    @media (max-width: 640px) {
      font-size: 32px;
    }
    a {
      text-decoration: none;
      color: #919695;
    }
    img {
      width: 25px;
      height: 15px;
      margin-right: 5px;
    }
  }
`;

export default MobileNavScroll;
